
div.personDetails {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: auto;
    grid-gap: 5px;
    grid-column-gap: 10px;
    padding: 5px;
}

div.personDetailsLabel {
    grid-column: 1;
    font-weight: bold;
    text-align: right;
}

div.personDetailsValue {
    grid-column: 2;
}

div.personDetailsValue::first-letter {
    text-transform:capitalize;
}

div.skillsTable {
    width: 100%;
    display: flex;;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

div.skillContainer {
    display: flex;
    background: #00000022;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
}

div.skillGrid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 20px;
}

div.skillName {
    text-align: right;
    grid-column: 1;
}

div.skillName::first-letter {
    text-transform:capitalize;
}

div.skillValue {
    text-align: center;
    grid-column: 2;
    background: #00000022;
    border-radius: 5px;
}
