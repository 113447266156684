
div.cancelTimeScaleButton {
    right: 260px;
}

div.speedUpTimeScaleButton {
    right: 200px;
}

div.boatButton {
    right: 140px;
}

div.mapButton {
    right: 80px;
}

div.menuButton {
    right: 20px;
}

div.pauseButton {
    right: 320px;
}
