#root {
  height: 100%;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}

button.mainButton {
  margin: 10px;
}

div.appContainer {
  height: 100%;
  width: 100%;
  position: relative;
}

div.bottomButtons {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

div.circleButton {
  position: absolute;
  top: 15px;
  width: 40px;
  height: 40px;
  border: 1px solid darkgray;
  box-shadow: 1px 1px #00000066;
  border-radius: 20px;
  background: beige;
}

div.circleButton:hover {
  background: lightgray;
}

div.fullScreenUi {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: beige;
  padding: 10px;
}

div.pixiWindow {
  height: 100%;
  width: 100%;
  background: aqua;
}

div.uiContent {
  width: 100%;
  height: 100%;
  padding: 5px;
}

h1 {
  font-family: 'Berkshire Swash', serif;
  font-size: xxx-large;
  font-weight: bold;
}

h2 {
  font-family: 'Libre Baskerville', serif;
  font-size: x-large;
  font-weight: bold;
}
span.buttonIcon {
  transform: translate(7px, 7px);
}
