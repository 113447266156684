
div.sideBar {
    position: absolute;
    top: 70px;
    right: 0;
    width: 200px;
    padding: 20px;
    background: beige;
    border: 1px solid darkgray;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: right 250ms;
    box-shadow: 1px 1px #00000066;
}

div.sideBarOpen {
    right: 0;
}

div.sideBarClosed {
    right: -200px;
}

div.sideBarItem:hover {
    background: lightgray;
}
