
div.startScreen {
    overflow: auto;
    height: 100%;
    width: 100%;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
}

div.startContent {
    background-color: #ffffffbb;
    width: 70%;
    margin: auto;
    padding: 10px;
    border-radius: 10px;
}
